<template>
    <div :class="['c-community__join--modal', { 'open': active }]">
        <div class="o-grid o-grid--center u-bg-white">
            <div class="o-grid__item u-width-full u-a-center u-p-t10 u-p-b5 u-p-x6">
                <img src="/img/ui/cancel.svg" class="c-profile__menu--close" alt="Close Icon" @click="close">
                <div class="c-community__join--community">
                    <img :src="logoUrl" alt="Community Logo" height="106" width="106">
                </div>
                <h4 class="u-color-blue u-text-24 u-semibold u-m-b2">{{ title }}</h4>
                <p class="u-m-b3" v-html="message"></p>
                <button class="c-btn c-btn--red c-btn--join is-outline" @click="close">Close</button>
                <button v-if="cta !== false" class="c-btn c-btn--join" @click="joinCommunity">{{ buttonText }}</button>
                <button v-if="cta === false && emit && buttonText != null" class="c-btn c-btn--join" @click="emitEvent">{{ buttonText }}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            active: false,
            communityId: typeof this.$root.community != 'undefined' ? this.$root.community.id : this.$root.placeholderCommunityId,
            communityThumbnail: typeof this.$root.community != 'undefined' ? this.$root.community.thumbnail : null,
            communityName: typeof this.$root.community != 'undefined' ? this.$root.community.name : null,
            logoUrl: typeof this.$root.community != 'undefined' ? this.$simpleStore.cloudinaryUrl(106, 106, 'img/communities/' + this.$root.community.id + '/' + this.$root.community.image, this.$root) : null,
            title: 'Join ',
            message: null,
            cta: '/join/',
            buttonText: 'Join this community',
            emit: null,
            closeCta: null
        }
    },
    created() {
        this.cta += this.communityId;
        this.title += this.communityName;
        this.$root.$on('show-community-modal', (data) => {
            this.message = data.message;
            if (typeof data.cta != 'undefined') {
                this.cta = data.cta;
            }
            if (typeof data.buttonText != 'undefined') {
                this.buttonText = data.buttonText;
            }
            if (typeof data.title != 'undefined') {
                this.title = data.title;
            }
            if (typeof data.communityId != 'undefined') {
                this.communityId = data.communityId;
            }
            if (typeof data.communityName != 'undefined') {
                this.communityName = data.communityName;
            }
            if (typeof data.communityThumbnail != 'undefined') {
                this.communityThumbnail = data.communityThumbnail;
            }
            if (typeof data.communityId != 'undefined' && typeof data.communityThumbnail != 'undefined') {
                this.logoUrl = this.$simpleStore.cloudinaryUrl(106, 106, 'img/communities/' + this.communityId + '/' +  this.communityThumbnail, this.$root);
            }
            if (typeof data.emit != 'undefined') {
                this.emit = data.emit;
            }
            this.active = true;
            this.$root.$el.classList.add('faded');
            console.log('modal data', data);
            console.log(this);
        });
    },
    mounted() {

    },
    methods: {
        close() {
            this.$root.$el.classList.remove('faded');
            this.$root.$el.classList.add('unfaded');
            setTimeout(() => {
                this.$root.$el.classList.remove('unfaded');
            }, 350);
            this.active = false;
            if (this.emit === 'close-invite-panel') {
                this.$root.$emit(this.emit);
            }
            if (this.closeCta != null) {
                window.location = this.closeCta;
            }
        },
        emitEvent() {
            this.$root.$emit(this.emit);
            this.close();
        },
        joinCommunity() {
            this.$http.get(this.cta).then(response => {
                let data = response.data;
                console.log('data joinCommunity in modal');
                console.log(data);
                // User not logged in
                if (data.rU === false) {
                    this.title = 'You are not registered/logged in';
                    this.message = 'Please login or register to join a community';
                    this.cta = false;
                    this.active = true;
                } else if (data.s === false) {
                    this.title = 'Already joined';
                    this.message = 'You have previously joined this community';
                    this.cta = false;
                    this.active = true;
                } else {
                    this.$root.is_member = 1;
                    this.title = 'Successfully joined';
                    this.message = 'You have successfully joined the ' + this.communityName + ' community\n';
                    if (typeof data.joined != 'undefined' && data.joined) {
                        this.message += 'and liked this post';
                    }
                    this.cta = false;
                    // TODO: update cached feed and explore sections after becoming a member - for now just delete and redirect
                    // remove feed and explore cache to they now refactor correctly on reload
                    sessionStorage.removeItem('feed');
                    sessionStorage.removeItem('explore');
                    sessionStorage.removeItem('c' + this.communityId);
                    this.closeCta = window.location.href;
                }
            }, response => {
                console.log('Errored:');
                console.log(response);
            });
        }
    }
}
</script>
