var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["c-community__join--modal", { open: _vm.active }] },
    [
      _c("div", { staticClass: "o-grid o-grid--center u-bg-white" }, [
        _c(
          "div",
          {
            staticClass:
              "o-grid__item u-width-full u-a-center u-p-t10 u-p-b5 u-p-x6",
          },
          [
            _c("img", {
              staticClass: "c-profile__menu--close",
              attrs: { src: "/img/ui/cancel.svg", alt: "Close Icon" },
              on: { click: _vm.close },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "c-community__join--community" }, [
              _c("img", {
                attrs: {
                  src: _vm.logoUrl,
                  alt: "Community Logo",
                  height: "106",
                  width: "106",
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "h4",
              { staticClass: "u-color-blue u-text-24 u-semibold u-m-b2" },
              [_vm._v(_vm._s(_vm.title))]
            ),
            _vm._v(" "),
            _c("p", {
              staticClass: "u-m-b3",
              domProps: { innerHTML: _vm._s(_vm.message) },
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "c-btn c-btn--red c-btn--join is-outline",
                on: { click: _vm.close },
              },
              [_vm._v("Close")]
            ),
            _vm._v(" "),
            _vm.cta !== false
              ? _c(
                  "button",
                  {
                    staticClass: "c-btn c-btn--join",
                    on: { click: _vm.joinCommunity },
                  },
                  [_vm._v(_vm._s(_vm.buttonText))]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.cta === false && _vm.emit && _vm.buttonText != null
              ? _c(
                  "button",
                  {
                    staticClass: "c-btn c-btn--join",
                    on: { click: _vm.emitEvent },
                  },
                  [_vm._v(_vm._s(_vm.buttonText))]
                )
              : _vm._e(),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }